const state = () => ({
  status: 'stop',
  openComponent: '',

})

const getters = {
  status: state => state.status,
  openComponent: state => state.openComponent,
}

const mutations = {
  SET_PLAYER_STATUS: (state, status) => {
    state.status = status;
  },
  SET_COMPONENT: (state, component) => {
    if (state.openComponent === component) {
      state.openComponent = ''
    }else {
      state.openComponent = component;
    }
  },
}

const actions = {
  PLAY: (context) => {
    context.commit('SET_PLAYER_STATUS', 'play')
  },
  PAUSE: (context) => {
    context.commit('SET_PLAYER_STATUS', 'pause')
  },
  STOP: (context) => {
    context.commit('SET_PLAYER_STATUS', 'stop')
  },
  OPEN_COMPONENT: (context, {component}) => {
    context.commit('SET_COMPONENT', component)
    if (state.openComponent === component) {
      state.openComponent = ''
    }else {
      state.openComponent = component;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}