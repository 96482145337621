const state = () => ({
  status: 'stop',
  contentType: 'stop',
  openExtended: '',
  openAction: '',
  showPlayButton: true,
  showControls: true,
  showStreams: true,
  showCalendar: true,
  audioguideActive: false,
  settingsOpen: false,
  scenesActive: false,
  subtitlesActive: null,
  subtitlesShow: false,
  extendedOpen: false,
  currentStream: null,
  subtitlesAvailable: [
    'ru',
    'en'
  ],
  currentLayout: 'five',
  extendedEnabled: [],
  actionsEnabled: [],
  streams: [
    {
      id: 1,
      title: {
        ru: 'Угловой',
        en: 'Angular'
      },
      description: {
        ru: 'Трансляция футбольного матча',
        en: 'Football match broadcast',
      },
      cover: '/images/video/uglovoy.jpg',
      style: {
        top: '30px',
        left: '-32px'
      }
    },
    {
      id: 2,
      title: {
        ru: 'Зона замены игроков',
        en: 'Player substitution area'
      },
      description: {
        ru: 'Трансляция футбольного матча',
        en: 'Football match broadcast',
      },
      cover: '/images/video/zamena.jpg',
      style: {
        bottom: '-30px',
        left: '320px'
      }
    },
    {
      id: 3,
      title: {
        ru: 'Вратарская площадка',
        en: 'Goalmouth'
      },
      description: {
        ru: 'Трансляция футбольного матча',
        en: 'Football match broadcast',
      },
      cover: '/images/video/penalti.jpg',
      style: {
        top: '146px',
        right: '-32px'
      }
    },
  ],
  extendedFeatures: [
    {
      component: 'mvs-scenes',
      icon: 'playlist',
      key: 'scenes',
    },
    {
      component: 'mvs-audioguide',
      icon: 'audioguide',
      key: 'audioguide'
    },
    {
      component: 'mvs-subtitles',
      icon: 'subtitles',
      key: 'subtitles',
    },
  ],
  actions: [
    {
      component: 'mvs-map',
      icon: 'map',
      key: 'map'
    },
    {
      component: 'mvs-layout',
      icon: 'layout',
      key: 'layout'
    }
  ],
  layouts: {
    single: 1,
    four: 4,
    five: 5,
    six: 6
  }
})

const getters = {
  settingsOpen: state => state.settingsOpen,
  openAction: state => state.openAction,
  subtitlesAvailable: state => state.subtitlesAvailable,
  currentLayout: state => state.currentLayout,
  layouts: state => state.layouts,
  contentType: state => state.contentType,
  currentStream: state => state.currentStream,
  subtitlesActive: state => state.subtitlesActive,
  audioguideActive: state => state.audioguideActive,
  scenesActive: state => state.scenesActive,
  streams: state => state.streams,

  extendedOpen: state => state.extendedOpen,
  extendedEnabled: state => state.extendedEnabled,
  extendedFeatures: state => state.extendedFeatures,

  actions: state => state.actions,
  actionsEnabled: state => state.actionsEnabled,
  status: state => state.status,
  showPlayButton: state => state.showPlayButton,
  showControls: state => state.showControls,
  showStreams: state => state.showStreams,
  showCalendar: state => state.showCalendar,
  subtitlesShow: state => state.subtitlesShow,
  currentLayoutArray: (state) => {
    return new Array(+state.layouts[state.currentLayout])
  },
}

const mutations = {
  SHOW_SETTINGS: (state) => {
    state.settingsOpen = true;
    state.showStreams = false;
    state.showControls = false;
    state.extendedOpen = false;
    state.showPlayButton = false;
  },
  TOGGLE_SETTINGS: (state) => {
    state.settingsOpen = !state.settingsOpen;
    state.showStreams = !state.settingsOpen;
    state.showControls = !state.settingsOpen;
    state.showPlayButton = !state.settingsOpen;
  },
  CLOSE_SETTINGS: (state) => {
    state.settingsOpen = false;
    state.showStreams = true;
    state.showControls = true;
    state.showPlayButton = true;
  },
  SET_SUBTITLES_ACTIVE: (state, lang) => {
    if (state.subtitlesActive === lang) {
      state.subtitlesActive = null
      const index = [...state.extendedEnabled].map(option => option.component).indexOf('mvs-scenes');
      state.extendedEnabled.splice(index, 1);
    }else{
      state.subtitlesActive = lang;
    }
    state.subtitlesShow = false
    state.extendedOpen = false
  },
  SHOW_EXTENDED: (state, isShow) => {
    state.extendedOpen = isShow;
  },
  SHOW_CONTROLS: (state, isShow) => {
    state.showControls = isShow;
  },
  SHOW_STREAMS: (state, isShow) => {
    state.showStreams = isShow;
  },
  SET_CURRENT_STREAM: (state, stream) => {
    console.log('SET_CURRENT_STREAM', stream);
    state.currentStream = stream;
  },
  SET_PLAYER_STATUS: (state, status) => {
    state.status = status;
  },
  ADD_ACTION_ENABLED: (state, component) => {
    const exist = state.actionsEnabled.find(option => option.component === component.component)
    if (!exist) {
      state.actionsEnabled.push(component)
    }
  },
  REMOVE_ACTION_ENABLED: (state, component) => {
    const index = [...state.actionsEnabled].map(option => option.component).indexOf(component.component);
    state.actionsEnabled.splice(index, 1);
  },
  CLEAR_ACTION_ENABLED: (state) => {
    state.actionsEnabled = []
  },
  OPEN_ACTION: (state, component) => {
    console.log('OPEN_ACTION', component);
    state.openAction = component.component
  },
  CLOSE_ACTION: (state) => {
    state.openAction = ''
  },
  ADD_EXTENDED_ENABLED: (state, component) => {
    const exist = state.extendedEnabled.find(option => option.component === component.component)
    if (!exist) {
      state.extendedEnabled.push(component)
    }
  },
  REMOVE_EXTENDED_ENABLED: (state, component) => {
    const index = [...state.extendedEnabled].map(option => option.component).indexOf(component.component);
    state.extendedEnabled.splice(index, 1);
  },
  CLEAR_EXTENDED_ENABLED: (state) => {
    state.extendedEnabled = []
  },
  CLOSE_EXTENDED: (state, extended) => {
    state[`${extended.key}Active`] = false
    state.openExtended = ''
  },
  SET_EXTENDED: (state, component) => {
    console.log('SET_EXTENDED', component);
    state.openExtended = component.component;
    if (component.key === 'subtitles') {
      state.subtitlesShow = !state.subtitlesShow
      state.extendedOpen = false
    }else{
      state[`${component.key}Active`] = true
    }

  },
  SET_ACTION: (state, component) => {
    state.openAction = component.component;
  },
}

const actions = {
  PLAY: (context) => {
    context.commit('SET_PLAYER_STATUS', 'play')
  },
  PAUSE: (context) => {
    context.commit('SET_PLAYER_STATUS', 'pause')
  },
  STOP: (context) => {
    context.commit('SET_PLAYER_STATUS', 'stop')
  },
  OPEN_EXTENDED: (_, component) => {
    if (_.state.openExtended === component.component) {
      if (component.key === 'subtitles') {
        _.commit('SHOW_STREAMS', true)
        _.commit('SHOW_CONTROLS', true)
        _.commit('ADD_EXTENDED_ENABLED', component)
        _.commit('SET_EXTENDED', component)
      }else{
        _.commit('SHOW_STREAMS', true)
        _.commit('SHOW_CONTROLS', true)
        _.commit('REMOVE_EXTENDED_ENABLED', component)
        _.commit('CLOSE_EXTENDED', component)
      }
    }else{
      _.commit('ADD_EXTENDED_ENABLED', component)
      _.commit('SHOW_EXTENDED', false)
      _.commit('SET_EXTENDED', component)
    }
  },
  OPEN_ACTION: (_, component) => {
    if (_.state.openAction === component.component) {
      _.commit('SHOW_STREAMS', true)
      _.commit('SHOW_CONTROLS', true)
      _.commit('REMOVE_ACTION_ENABLED', component)
      _.commit('CLOSE_ACTION')
    }else{
      _.commit('ADD_ACTION_ENABLED', component)
      _.commit('SET_ACTION', component)
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}