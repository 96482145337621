export const menu = {
  ru: {
    landing: {
      menu: {
        products: "Продукты",
        projects: "Проекты"
      }
    }
  },
  en: {
    landing: {
      menu: {
        products: "Products",
        projects: "Projects"
      }
    }
  }
}