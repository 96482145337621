<template>
  <div class="cookie-banner">
    <div class="cookie-banner-content">
      <p class="cookie-banner-content--text">
        {{ $t('coockieAggreement') }}
        <a class="cookie-banner-content--link" href="/privacy">
          {{ $t('policy') }}
        </a>
      </p>
    </div>
    <button
      class="cookie-banner--button"
      @click="$emit('cookie-accept')">
      {{ $t('agreement') }}
    </button>
  </div>
</template>
<script>
export default {
  name: 'CookieBanner',
  i18n: {
    messages: {
      ru: {
        coockieAggreement: 'На данном веб-сайте используются файлы cookie. Использование нашего веб-сайта означает, что Вы согласны с использованием файлов cookie и',
        policy: 'политикой обработки персональных данных',
        agreement: 'Я согласен'
      },
      en: {
        coockieAggreement: 'This website uses cookies. Using our website means you agree to the use of cookies and',
        policy: 'personal data processing policy',
        agreement: 'I agree'
      }
    }
  }
}
</script>
<style lang="sass" scoped>
  .cookie-banner
    position: fixed
    bottom: 10px
    right: 10px
    width: 300px
    z-index: 10
    padding: 12px 16px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    border-radius: 5px
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)
    background-color: #5c5c5c
    font-size: 18px
    @media(max-width: 900px)
      flex-direction: column
    &-content
      // max-width: 630px
      &--text
        color: white
        line-height: 22px
        font-size: 14px
        font-weight: 300
        margin-bottom: 0
      &--link
        font-size: 14px
        color: white
        font-weight: 500
        text-decoration: underline
        line-height: 22px
        &:hover
          color: darken(#fff, 15%)
    &--button
      margin-top: 15px
      border-radius: 5px
      border: solid 1px rgba(187, 187, 187, 0.24)
      background-color: #212121
      font-size: 14px
      padding-left: 35px
      padding-right: 35px
      color: white
      font-weight: 300
      line-height: 22px
      height: 26px
      cursor: pointer
      &:hover
        background-color: lighten(#212121, 15%)
      @media(max-width: 900px)
        margin-top: 20px
  .mobile
    &.portrait
      .cookie-banner
        right: 20px
        left: 20px
        margin: 0 auto
    &.landscape
      .cookie-banner
        width: 90%
        right: 20px
        left: 20px
        margin: 0 auto
      .cookie-banner--button
        height: 40px
</style>

