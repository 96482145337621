export const video = {
  ru: {
    landing: {
      video: {
        title: 'МУЛЬТИПОТОКОВЫЙ',
        opportunities: 'возможности',
        howItWorks: [
          'КАК ЭТО РАБОТАЕТ?',
          'ДЕМОНСТРАЦИОННАЯ ВЕРСИЯ'
        ],
        interactive: {
          title: 'интерактивные опции',
          text: [
            'Иллюстрация интерфейса подключаемых интерактивных опций.',
            'Нажимайте на кнопки, чтобы понять как активируются опции и как меняется интерфейс.'
          ]
        },
        playlist: {
          title: 'плейлисты',
          text: [
            'Иллюстрация интерфейса интерактивной опции.',
            'Нажимайте на кнопки, чтобы понять как это работает и как меняется интерфейс.',
          ]
        },
        extended: 'расширенные функции'
      }
    }
  },
  en: {
    landing: {
      video: {
        title: 'MULTI-STREAM',
        opportunities: 'opportunities',
        howItWorks: [
          'HOW IT WORKS?',
          'DEMONSTRATION VERSION',
        ],
        interactive: {
          title: 'interactive options',
          text: [
            'Illustration of the interface of pluggable interactive options.',
            'Click on the buttons to understand how options are activated and how the interface changes.'
          ]
        },
        playlist: {
          title: 'playlists',
          text: [
            'Illustration of the interface of the interactive option.',
            'Click on the buttons to understand how it works and how the interface changes.',
          ]
        },
        extended: 'advanced features'
      }
    }
  }
}