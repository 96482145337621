export const landing = {
  ru: {
    landing: {
      about: "О проекте",
      features: "Возможности",
      solutions: "Решения",
      partners: "Партнеры",
      contacts: "Контакты",
      sections: {
        about: [
          'MUVIS (multi viewpoint selection) – это взгляд на события с разных точек зрения.',
          'Теперь Вы можете создавать мультипотоковое видео с различными интерактивными опциями. Это позволит менять ракурс при просмотре трансляции на любом девайсе, предлагать альтернативный сценарий события, что повысит вовлеченность Зрителя в просмотр и позволит реализовать новый пользовательский опыт.',
          'Расширяйте возможности Вашего видео и управляйте просмотром, как никогда раньше.'
        ],
        features: [
          'MUVIS - это технология создания мультипотоковых онлайн-трансляций в составе видеоплатформы с расширенными интерактивными опциями.',
          'MUVIS - это индивидуальные решения на основе проприетарной технологии. Возможность создания тематических онлайн-видеосервисов и медиапорталов с использованием гибкой системы администрирования с централизованным контролем распространения медиаконтента.',
          'MUVIS - это модель масштабирования проектов путем глубокой интеграции и клонированием веб-интерфейсов на сторонние сайты.'
        ]
      },
    }
  },
  en: {
    landing: {
      about: "About",
      features: "Features",
      solutions: "Solutions",
      partners: "Partners",
      contacts: "Contacts",
      sections: {
        about: [
          'MUVIS (multi viewpoint selection) - it is a look at events from different points of view.',
          `Now you can create multi-streaming video with various interactive options. This will allow you to change the angle when watching the broadcast on any device, offer an alternative scenario of the event, which will increase the viewer's involvement in viewing and will allow you to implement a new user experience.`,
          'Expand the possibilities of your video and control the viewing like never before.'
        ],
        features: [
          'MUVIS - is a technology for creating multi-streaming online broadcasts as part of a video platform with advanced interactive options.',
          'MUVIS - individual projects based on proprietary technology. The ability to create thematic online video services and media portals using a flexible administration system with centralized control over the distribution of media content.',
          'MUVIS - a model for scaling projects through deep integration and cloning web interfaces to third-party sites.'
        ]
      },
    }
  }
}