const merge = require('deepmerge')

import { system } from './system'
import { landingLocales } from './landing'
import { player } from './player'
// import { features } from './video/tabs/features'

export const componentsI18n = merge.all([
  system,
  landingLocales,
  player,
])