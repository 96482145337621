export const chat = {
  ru: {
    landing: {
      tabs: {
        chat: {
          chats: {
            title: "Индивидуальные и групповые чаты",
            text: "Корпоративный мессенджер"
          },
          bonus: {
            title: "Бонусы и подарки",
            text: "Программа лояльности"
          },
          calendar: {
            title: "Календарь мероприятий",
            text: "События, акции, индивидуальные и групповые занятия"
          },
        }
      }
    }
  },
  en: {
    landing: {
      tabs: {
        chat: {
          chats: {
            title: "индивидуальные и групповые чаты",
            text: "Где это применимо, кому это надо."
          },
          bonus: {
            title: "бонусы и подарки",
            text: "Программа лояльности"
          },
          calendar: {
            title: "календарь мероприятий",
            text: "События, акции, индивидуальные и групповые занятия"
          },
        }
      }
    }
  }
}