import Vue from "vue";
import VueCookies from 'vue-cookies'
Vue.use(VueCookies);

const state = () => ({
  currentLang: 'ru',
  activeMenu: '',
  isMobileMenuOpen: false,
  cookies: {
    acceptCookies: window.$cookies.get('accept-cookies')
  },
  menu: {
    products: {
      video: 'MUVIS.VIDEO',
      contact: 'MUVIS.CONTACT',
      quiz: 'MUVIS.QUIZ',
    },
    projects: 'projects'
  },
  slides: [
    {
      title: {
        ru: 'Тематические медиапорталы',
        en: 'Thematic media portals'
      },
      text: {
        ru: 'отраслевые онлайн-проекты для эффективного взаимодействия с целевой аудиторией',
        en: 'industry-specific online projects for effective interaction with  the target audience.'
      },
    },
    {
      title: {
        ru: 'Встраиваемые сервисы',
        en: 'Embedded services'
      },
      text: {
        ru: 'модульные подключаемые сервисы, расширяющие функциональные и технические характеристики веб-сайтов',
        en: 'modular plug-in services that extend the functional and technical characteristics of websites.'
      },
    },
    {
      title: {
        ru: 'Медиавизитки',
        en: 'Media Cards'
      },
      text: {
        ru: 'мобильные интерфейсы с медиаконтентом',
        en: 'mobile interfaces with media content.'
      },
    },
    {
      title: {
        ru: 'Мобильные компаньоны',
        en: 'Mobile Companions'
      },
      text: {
        ru: 'цифровые решения для печатных СМИ',
        en: 'digital solutions for print media.'
      },
    },
  ]
})

const getters = {
  cookies: state => state.cookies.acceptCookies,
  isMobileMenuOpen: state => state.isMobileMenuOpen,
  currentLang: state => state.currentLang,
  activeMenu: state => state.activeMenu,
  menu: state => state.menu,
  slides: state => state.slides,
}

const mutations = {
  COOKIES_ACCEPT: (state) => {
    state.cookies.acceptCookies = true;
    window.$cookies.set('accept-cookies', true);
  },
  SET_MOBILE_MENU_OPEN: (state, isOpen) => {
    state.isMobileMenuOpen = isOpen;
  },
  SET_CURRENT_LANG: (state, lang) => {
    state.currentLang = lang;
  },
  SET_ACTIVE_MENU: (state, item) => {
    console.log('SET_ACTIVE_MENU', item);
    state.activeMenu = item;
  },
}

const actions = {
  COOKIES_ACCEPTED: ({commit, dispatch}) => {
    commit('COOKIES_ACCEPT');
    dispatch('SET_USER_SETTINGS', {key: 'web.show.cookieBanner', value: false})
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}