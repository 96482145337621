export const quizForms = {
  ru: {
    landing: {
      tabs: {
        quizForms: {
          anonymousPolls: {
            title: "Анонимные опросы",
            text: "Формирование статистики, выявление ожидания и потребности аудитории"
          },
          personalizedQuestions: {
            title: "Персонализированные опросы",
            text: "Таргетирование пользователей, оценка услуги или сервиса"
          },
          testing: {
            title: "Тестирование",
            text: "Оценка полученных знаний и статистический анализ"
          },
          quizLanding: {
            title: "квиз-лендинги",
            text: "Сбор лидов (заявок) в коммерческих целях"
          }
        }
      }
    }
  },
  en: {
    landing: {
      tabs: {
        quizForms: {
          anonymousPolls: {
            title: "анонимные опросы",
            text: "формирование статистики, выявление ожидания и потребности аудитории"
          },
          personalizedQuestions: {
            title: "персонализированные опросы",
            text: "таргетирование пользователей, оценка услуги или сервиса"
          },
          testing: {
            title: "тестирование",
            text: "оценка полученных знаний и статистический анализ"
          },
          quizLanding: {
            title: "квиз-лендинги",
            text: "сбор лидов (заявок) в коммерческих целях"
          }
        }
      }
    }
  }
}