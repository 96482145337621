export const contactFeatures = {
  ru: {
    landing: {
      tabs: {
        contactFeatures: {
          integration: {
            title: "Встраивание во внешние веб-сайты",
            text: "Внешнее встраивание на веб-сайты в качестве функционального раздела или модуля"
          },
          cloud: {
            title: "Облачный сервис",
            text: "Оптимизация ресурсов заказчика за счёт использования облачных решений"
          },
          share: {
            title: "Масштабирование",
            text: "Клонирование встроенных сервисов для расширения количества интеграций"
          },
          statistics: {
            title: "Статистика и аналитика",
            text: "Данные активности пользователей"
          }
        }
      }
    }
  },
  en: {
    landing: {
      tabs: {
        contactFeatures: {
          integration: {
            title: "встраивание во внешние веб-сайты",
            text: "Внешнее встраивание на веб-сайты в качестве функционального раздела или модуля"
          },
          cloud: {
            title: "облачный сервис",
            text: "оптимизация ресурсов заказчика за счёт использования облачных решений"
          },
          share: {
            title: "статистика и аналитика",
            text: "данные активности пользователей"
          },
          statistics: {
            title: "масштабирование",
            text: "клонирование встроенных сервисов для расширения количества интеграций"
          }
        }
      }
    }
  }
}