const merge = require('deepmerge')
import { landing } from './landing'
import { menu } from './menu'
import { videoPage } from './video'
import { quizPage } from './quiz'
import { contactPage } from './contact'

export const landingLocales = merge.all([
  landing,
  menu,
  videoPage,
  quizPage,
  contactPage,
])