import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('@/views/Products.vue'),
    children: [
      {
        path: 'video',
        name: 'video',
        component: () => import('@/views/Video.vue')
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/views/Contact.vue')
      },
      {
        path: 'quiz',
        name: 'quiz',
        component: () => import('@/views/Quiz.vue')
      }
    ]
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/Projects.vue'),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/Privacy.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  next()
  store.commit(`landing/SET_MOBILE_MENU_OPEN`, false)
});

export default router
