export const extended = {
  ru: {
    landing: {
      tabs: {
        extended: {
          subscription: {
            title: "подписка",
            text: "Монетизируйте контент, установив стоимость просмотра или подключив подписку."
          },
          private: {
            title: "закрытый просмотр",
            text: "Создавайте закрытые каналы и трансляции, ограничивая просмотр персональным приглашением"
          },
          notification: {
            title: "оповещения",
            text: "Подключайте оповещения, чтобы не пропустить начало трансляции."
          }
        }
      }
    }
  },
  en: {
    landing: {
      tabs: {
        extended: {
          subscription: {
            title: "subscription",
            text: "Monetize content by setting a cost per view or connecting a subscription."
          },
          private: {
            title: "private view",
            text: "Create private channels and broadcasts, limiting viewing to a personal invitation"
          },
          notification: {
            title: "notifications",
            text: "Connect alerts so you don't miss the start of the broadcast."
          }
        }
      }
    }
  }
}