import {
  mapGetters,
  // mapMutations,
  // mapActions
} from 'vuex';
export default {
  data: () => ({

  }),
  computed: {
    ...mapGetters({
      currentLang : 'landing/currentLang',
    }),
  },
  methods: {

  }
};
