export const system = {
  ru: {
    system: {
      abuse: {
        title: 'Жалоба',
        reasons: {
          spam: 'Спам',
          violence: 'Насилие',
          childPornography: 'Детская порнография',
          pornography: 'Порнография',
          other: 'Другое'
        }
      },
      form: {
        name: 'Как к Вам обращаться?',
        contact: 'Контакт для связи',
        message: 'Текст сообщения'
      },
      policy: 'Политика обработки персональных данных',
      requisites: 'ООО "МВС МЕДИА" ИНН: 5038127622 / КПП: 503801001',
      addProfileTerm: 'Какой тип профиля выбрать?',
      copyInviteLink: 'Скопировать реферальную ссылку',
      lastBeenTitle: 'Был(а)',
      notIndicated: "Не указано",
      today: "Сегодня",
      all: "Всего",
      online: "В сети",
      offline: "Офлайн",
      back: "Назад",
      search: "Поиск",
      edit: "Редактировать",
      delete: "Удалить",
      audience: "Аудитория"
    }
  },
  en: {
    system: {
      abuse: {
        title: 'Abuse',
        reasons: {
          spam: 'Spam',
          violence: 'Violence',
          childPornography: 'Child pornography',
          pornography: 'Pornography',
          other: 'Other'
        }
      },
      form: {
        name: 'How can I call you?',
        contact: 'Contact for communication',
        message: 'Message text'
      },
      policy: 'Personal data processing policy',
      requisites: 'LLC "MVS MEDIA" INN: 5038127622 / KPP: 503801001',
      addProfileTerm: 'What type of profile should you choose?',
      copyInviteLink: 'Copy invite link',
      today: "Today",
      notIndicated: "not indicated",
      lastBeenTitle: 'Last seen',
      all: "All",
      online: "Online",
      back: "Back",
      offline: "Offline",
      search: "Search",
      edit: "Edit",
      delete: "Delete",
      audience: "Audience"
    }
  }
}