export const player = {
  ru: {
    player: {
      buttons: {
        playlist: "Плэйлист",
        audioguide: "Аудио-гид",
        subtitles: "Субтитры",
        map: "Карта ракурсов",
        layout: "Мультиокно",
      },
      features: {
        subtitles: 'The word "fox" occurs on the common names of species.'
      }
    }
  },
  en: {
    player: {
      buttons: {
        playlist: "Плэйлист",
        audioguide: "audioguide",
        subtitles: "subtitles",
        map: "map",
        layout: "layout",
      },
      features: {
        subtitles: 'Слово «лиса» встречается в общих названиях видов.'
      }
    }
  }
}