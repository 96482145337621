export const howItWorks = {
  ru: {
    landing: {
      tabs: {
        howItWorks: {
          multicam: {
            title: "многоракурсное видео",
            text: "Ознакомительный фрагмент многоракурсной съёмки концерта, прошедший в Московском международном Доме Музыки."
          },
          live: {
            title: "прямой эфир",
            text: "Фрагмент трансляции. Сычуань, Китай. Маленькие панды из Happiness Village Garden играют, спят, едят и растут в Центре панд Shenshuping Gengda! Гэнда"
          },
          scenario: {
            title: "сценарии",
            text: "Компиляция фрагмента эпизода «Альтернативная история» из анимационного фильма  «Любовь, смерть и роботы» (англ. Love, Death & Robots,), выпущенный в потоковом сервисе Netflix."
          }
        }
      }
    }
  },
  en: {
    landing: {
      tabs: {
        howItWorks: {
          multicam: {
            title: "multi-angle video",
            text: "An introductory fragment of a multi-angle filming of the concert, which took place at the Moscow International House of Music."
          },
          live: {
            title: "live",
            text: "Fragment of the broadcast. Sichuan, China. Little pandas from the Happiness Village Garden play, sleep, eat and grow at the Shenshuping Gengda Panda Center! Genda"
          },
          scenario: {
            title: "scripts",
            text: `A compilation of a snippet of the episode "Alternate History" from the animated film Love, Death & Robots, released on the streaming service Netflix.`
          }
        }
      }
    }
  }
}