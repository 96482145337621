const state = () => ({
  status: 'stop',
  contentType: 'stop',
  openExtended: '',
  openAction: '',
  showControls: true,
  showStreams: true,
  showCalendar: true,
  showPlaylist: true,
  audioguideActive: false,
  scenesActive: false,
  playlistActive: false,
  subtitlesActive: null,
  subtitlesShow: false,
  settingsOpen: false,
  extendedOpen: false,
  currentStream: null,
  currentDate: null,
  subtitlesAvailable: [
    'ru',
    'en'
  ],
  currentTitle: {
    ru: 'Как написать хороший текст для соцсетей',
    en: 'How to write good social media copy'
  },
  currentLayout: 'four',
  extendedEnabled: [],
  actionsEnabled: [],
  playlist: [
    {
      id: 1,
      date: '2020-10-04T22:44:30.652Z',
      title: {
        ru: 'курс | smm-менеджер',
        en: 'course | smm manager'
      },
      description: 'Очень длинное и подробное название видеопублика...',
      timetable: [
        {
          id: 1,
          time: '14:30',
          title: {
            ru: 'Как начать работать в соцсетях',
            en: 'How to get started on social media'
          },
          last: true,
          current: false,
          subscribed: false,
          duration: '01:10:02'
        },
        {
          id: 2,
          time: '14:50',
          title: {
            ru: 'Стратегия. почему её создание так важно',
            en: 'Strategy. why its creation is so important'
          },
          last: true,
          current: false,
          subscribed: false,
          duration: '02:39:00'
        },
        {
          id: 3,
          time: '16:10',
          title: {
            ru: 'Целевая аудитория',
            en: 'The target audience'
          },
          last: true,
          current: false,
          subscribed: false,
          duration: '00:59:43'
        },
        {
          id: 4,
          time: '17:40',
          title: {
            ru: 'Как написать хороший текст для соцсетей',
            en: 'How to write good social media copy'
          },
          last: false,
          current: true,
          subscribed: false,
          duration: '00:45:12'
        },
        {
          id: 5,
          time: '19:00',
          title: {
            ru: 'Сторителлинг',
            en: 'Storytellin'
          },
          last: false,
          current: false,
          subscribed: false,
          duration: '01:25:00'
        },
        {
          id: 6,
          time: '19:20',
          title: {
            ru: 'Бриффинг заказчика',
            en: 'Customer briefing'
          },
          last: false,
          current: false,
          subscribed: false,
          duration: '01:39:00'
        },
        {
          id: 7,
          time: '19:40',
          title: {
            ru: 'Как создать визуальное оформление для соцсетей',
            en: 'How to create visuals for social media'
          },
          last: false,
          current: false,
          subscribed: false,
          duration: '02:14:12'
        },
        {
          id: 8,
          time: '20:05',
          title: {
            ru: 'Бренд как эмоция и визуальное нишевание',
            en: 'Brand as emotion and visual niche'
          },
          last: false,
          current: false,
          subscribed: false,
          duration: '01:10:58'
        },
        {
          id: 9,
          time: '21:00',
          title: {
            ru: 'Таргетированная реклама',
            en: 'Targeted advertising'
          },
          last: false,
          current: false,
          subscribed: false,
          duration: '01:05:00'
        },
        {
          id: 10,
          time: '21:00',
          title: {
            ru: 'Рекламный кабинет FB',
            en: 'Advertising cabinet FB'
          },
          last: false,
          current: false,
          subscribed: false,
          duration: '00:46:12'
        },
        {
          id: 11,
          time: '21:00',
          title: {
            ru: 'Influencer - маркетинг',
            en: 'Influencer - marketing'
          },
          last: false,
          current: false,
          subscribed: false,
          duration: '00:45:23'
        },
        {
          id: 12,
          time: '21:00',
          title: {
            ru: 'Мессенджер - маркетинг',
            en: 'Messenger - marketing'
          },
          last: false,
          current: false,
          subscribed: false,
          duration: '00:45:08'
        },
        {
          id: 13,
          time: '21:00',
          title: {
            ru: 'Внедрение рассылок, чат-бота и автоворонок',
            en: 'Implementation of mailings, chatbots and auto funnels'
          },
          last: false,
          current: false,
          subscribed: false,
          duration: '01:50:29'
        },
        {
          id: 14,
          time: '21:00',
          title: {
            ru: 'Работа с аудиторией',
            en: 'Working with the audience'
          },
          last: false,
          current: false,
          subscribed: false,
          duration: '01:10:20'
        },
        {
          id: 15,
          time: '21:00',
          title: {
            ru: 'Аналитика сообщества',
            en: 'Community analytics'
          },
          last: false,
          current: false,
          subscribed: false,
          duration: '00:50:12'
        },
        {
          id: 16,
          time: '21:00',
          title: {
            ru: 'Работа с негативом, позитивом и репутацией',
            en: 'Dealing with negativity, positivity and reputation'
          },
          last: false,
          current: false,
          subscribed: false,
          duration: '00:43:14'
        },
        {
          id: 17,
          time: '21:00',
          title: {
            ru: 'Средства автоматизации процесса',
            en: 'Process automation tools'
          },
          last: false,
          current: false,
          subscribed: false,
          duration: '01:40:00'
        },
        {
          id: 18,
          time: '21:00',
          title: {
            ru: 'Организация работы по smm-проектам',
            en: 'Organization of work on smm projects'
          },
          last: false,
          current: false,
          subscribed: false,
          duration: '00:59:28'
        },
        {
          id: 19,
          time: '21:00',
          title: {
            ru: 'Тайм-менеджмент',
            en: 'Time management'
          },
          last: false,
          current: false,
          subscribed: false,
          duration: '02:00:00'
        },
      ]
    },
    {
      id: 2,
      date: '2020-10-05T22:44:30.652Z',
      title: 'Раку',
      description: 'Очень длинное и подробное название видеопублика...',
    },
    {
      id: 3,
      date: '2020-10-06T22:44:30.652Z',
      title: 'Ракурс',
      description: 'Очень длинное и подробное название видеопублика...',
    },
    {
      id: 4,
      date: '2020-10-06T22:44:30.652Z',
      title: 'Ракурс',
      description: 'Очень длинное и подробное название видеопублика...',
    },
    {
      id: 5,
      date: '2020-10-06T22:44:30.652Z',
      title: 'Ракурс',
      description: 'Очень длинное и подробное название видеопублика...',
    },
    {
      id: 6,
      date: '2020-10-06T22:44:30.652Z',
      title: 'Ракурс',
      description: 'Очень длинное и подробное название видеопублика...',
    },
    {
      id: 7,
      date: '2020-10-06T22:44:30.652Z',
      title: 'Ракурс',
      description: 'Очень длинное и подробное название видеопублика...',
    },
    {
      id: 8,
      date: '2020-10-06T22:44:30.652Z',
      title: 'Ракурс',
      description: 'Очень длинное и подробное название видеопублика...',
    },
    {
      id: 9,
      date: '2020-10-06T22:44:30.652Z',
      title: 'Ракурс',
      description: 'Очень длинное и подробное название видеопублика...',
    },
    {
      id: 10,
      date: '2020-10-06T22:44:30.652Z',
      title: 'Ракурс',
      description: 'Очень длинное и подробное название видеопублика...',
    },
    {
      id: 11,
      date: '2020-10-06T22:44:30.652Z',
      title: 'Ракурс',
      description: 'Очень длинное и подробное название видеопублика...',
    },
    {
      id: 12,
      date: '2020-10-06T22:44:30.652Z',
      title: 'Ракурс',
      description: 'Очень длинное и подробное название видеопублика...',
    },
    {
      id: 13,
      date: '2020-10-06T22:44:30.652Z',
      title: 'Ракурс',
      description: 'Очень длинное и подробное название видеопублика...',
    },
  ],
  calendar: [
    {
      id: 1,
      date: '2020-10-04T22:44:30.652Z',
      title: 'IT Конференция, день первый',
      description: 'Очень длинное и подробное название видеопублика...',
      timetable: [
        {
          id: 1,
          time: '00:00',
          title: 'Продвижение в Инстаграм 2021',
          last: true,
          current: false,
          subscribed: false,
        },
        {
          id: 1,
          time: '01:16',
          title: 'Умная лента',
          last: true,
          current: false,
          subscribed: false,
        },
        {
          id: 1,
          time: '02:03',
          title: 'Какие посты попадают в ленту',
          last: true,
          current: false,
          subscribed: false,
        },
        {
          id: 1,
          time: '03:42',
          title: 'Почему мало просмотров',
          last: false,
          current: true,
          subscribed: false,
        },
        {
          id: 1,
          time: '04:40',
          title: 'Рекомендации в ленте',
          last: false,
          current: false,
          subscribed: false,
        },
        {
          id: 1,
          time: '05:35',
          title: 'Как попадать в рекомендации',
          last: false,
          current: false,
          subscribed: false,
        },
        {
          id: 1,
          time: '06:30',
          title: 'Алгоритмы умной ленты',
          last: false,
          current: false,
          subscribed: false,
        },
        {
          id: 1,
          time: '07:53',
          title: 'Карусель повышает охваты',
          last: false,
          current: false,
          subscribed: false,
        },
        {
          id: 1,
          time: '08:28',
          title: 'Почему нельзя брать чужие фото',
          last: false,
          current: false,
          subscribed: false,
        },
        {
          id: 1,
          time: '10:19',
          title: 'Раздел рекомендованное',
          last: false,
          current: false,
          subscribed: false,
        },
        {
          id: 1,
          time: '11:16',
          title: 'Алгоритм преследования',
          last: false,
          current: false,
          subscribed: false,
        },
        {
          id: 1,
          time: '13:23',
          title: 'Отчего зависит охват сторис',
          last: false,
          current: false,
          subscribed: false,
        },
        {
          id: 1,
          time: '15:08',
          title: 'Какие сторис повышают охват',
          last: false,
          current: false,
          subscribed: false,
        },
        {
          id: 1,
          time: '16:33',
          title: 'колько публиковать историй',
          last: false,
          current: false,
          subscribed: false,
        },
        {
          id: 1,
          time: '18:36',
          title: 'Алгоритмы инстаграм в 2021 году',
          last: false,
          current: false,
          subscribed: false,
        },
        {
          id: 1,
          time: '21:25',
          title: 'Повышаем охваты в сторис',
          last: false,
          current: false,
          subscribed: false,
        },
        {
          id: 1,
          time: '22:31',
          title: 'Обучение SMM и продвижению instagram',
          last: false,
          current: false,
          subscribed: false,
        },
      ]
    },
    {
      id: 2,
      date: '2020-10-05T22:44:30.652Z',
      title: 'Ракурс 1',
      description: 'Очень длинное и подробное название видеопублика...',
    },
    {
      id: 3,
      date: '2020-10-06T22:44:30.652Z',
      title: 'Ракурс 1',
      description: 'Очень длинное и подробное название видеопублика...',
    },
  ],
  extendedFeatures: [],
  actions: [],
  layouts: {
    single: 1,
    four: 4,
    five: 5,
    six: 6
  }
})

const getters = {
  currentTitle: state => state.currentTitle,
  settingsOpen: state => state.settingsOpen,
  openAction: state => state.openAction,
  calendar: state => state.calendar,
  playlist: state => state.playlist,
  subtitlesAvailable: state => state.subtitlesAvailable,
  currentLayout: state => state.currentLayout,
  layouts: state => state.layouts,
  contentType: state => state.contentType,
  currentStream: state => state.currentStream,
  subtitlesActive: state => state.subtitlesActive,
  audioguideActive: state => state.audioguideActive,
  scenesActive: state => state.scenesActive,
  playlistActive: state => state.playlistActive,
  streams: state => state.streams,

  extendedOpen: state => state.extendedOpen,
  extendedEnabled: state => state.extendedEnabled,
  extendedFeatures: state => state.extendedFeatures,

  currentDate: state => state.currentDate,
  actions: state => state.actions,
  actionsEnabled: state => state.actionsEnabled,
  status: state => state.status,
  showControls: state => state.showControls,
  showCalendar: state => state.showCalendar,
  showPlaylist: state => state.showPlaylist,
  showStreams: state => state.showStreams,
  subtitlesShow: state => state.subtitlesShow,
  currentLayoutArray: (state) => {
    return new Array(+state.layouts[state.currentLayout])
  },
}

const mutations = {
  SET_SUBTITLES_ACTIVE: (state, lang) => {
    if (state.subtitlesActive === lang) {
      state.subtitlesActive = null
      const index = [...state.extendedEnabled].map(option => option.component).indexOf('mvs-scenes');
      state.extendedEnabled.splice(index, 1);
    }else{
      state.subtitlesActive = lang;
    }
    state.subtitlesShow = false
    state.extendedOpen = false
  },
  SET_CURRENT_TITLE: (state, title) => {
    state.currentTitle = title;
  },
  SHOW_SETTINGS: (state) => {
    state.settingsOpen = true;

    state.showStreams = false;
    state.showControls = false;
    state.extendedOpen = false;
  },
  TOGGLE_SETTINGS: (state) => {
    state.settingsOpen = !state.settingsOpen;

    state.showStreams = !state.showStreams;
    state.showControls = !state.showControls;
    state.extendedOpen = !state.extendedOpen;
  },
  CLOSE_SETTINGS: (state) => {
    state.settingsOpen = false;

    state.showStreams = true;
    state.showControls = true;
    state.extendedOpen = true;
  },
  SHOW_EXTENDED: (state, isShow) => {
    state.extendedOpen = isShow;
  },
  SHOW_CONTROLS: (state, isShow) => {
    state.showControls = isShow;
  },
  SHOW_STREAMS: (state, isShow) => {
    state.showStreams = isShow;
  },
  SET_CURRENT_DATE: (state, date) => {
    console.log('SET_CURRENT_DATE', date);
    state.currentDate = date;
  },
  SET_CURRENT_STREAM: (state, stream) => {
    state.currentStream = stream;
  },
  SET_PLAYER_STATUS: (state, status) => {
    state.status = status;
  },
  ADD_ACTION_ENABLED: (state, component) => {
    const exist = state.actionsEnabled.find(option => option.component === component.component)
    if (!exist) {
      state.actionsEnabled.push(component)
    }
  },
  REMOVE_ACTION_ENABLED: (state, component) => {
    const index = [...state.actionsEnabled].map(option => option.component).indexOf(component.component);
    state.actionsEnabled.splice(index, 1);
  },
  CLEAR_ACTION_ENABLED: (state) => {
    state.actionsEnabled = []
  },
  OPEN_ACTION: (state, component) => {
    state.openAction = component.component
  },
  CLOSE_ACTION: (state) => {
    state.openAction = ''
  },
  ADD_EXTENDED_ENABLED: (state, component) => {
    const exist = state.extendedEnabled.find(option => option.component === component.component)
    if (!exist) {
      state.extendedEnabled.push(component)
    }
  },
  REMOVE_EXTENDED_ENABLED: (state, component) => {
    const index = [...state.extendedEnabled].map(option => option.component).indexOf(component.component);
    state.extendedEnabled.splice(index, 1);
  },
  CLEAR_EXTENDED_ENABLED: (state) => {
    state.extendedEnabled = []
  },
  CLOSE_EXTENDED: (state, extended) => {
    state[`${extended.key}Active`] = false
    state.openExtended = ''
  },
  SET_EXTENDED: (state, component) => {
    state.openExtended = component.component;
    if (component.key === 'subtitles') {
      state.subtitlesShow = !state.subtitlesShow
      state.extendedOpen = false
    }else{
      state[`${component.key}Active`] = true
    }

  },
  SET_ACTION: (state, component) => {
    state.openAction = component.component;
  },
}

const actions = {
  PLAY: (context) => {
    context.commit('SET_PLAYER_STATUS', 'play')
  },
  PAUSE: (context) => {
    context.commit('SET_PLAYER_STATUS', 'pause')
  },
  STOP: (context) => {
    context.commit('SET_PLAYER_STATUS', 'stop')
  },
  OPEN_EXTENDED: (_, component) => {
    if (_.state.openExtended === component.component) {
      if (component.key === 'subtitles') {
        _.commit('SHOW_STREAMS', true)
        _.commit('SHOW_CONTROLS', true)
        _.commit('ADD_EXTENDED_ENABLED', component)
        _.commit('SET_EXTENDED', component)
      }else{
        _.commit('SHOW_STREAMS', true)
        _.commit('SHOW_CONTROLS', true)
        _.commit('REMOVE_EXTENDED_ENABLED', component)
        _.commit('CLOSE_EXTENDED', component)
      }
    }else{
      _.commit('ADD_EXTENDED_ENABLED', component)
      _.commit('SHOW_EXTENDED', false)
      _.commit('SET_EXTENDED', component)
    }
  },
  OPEN_ACTION: (_, component) => {
    if (_.state.openAction === component.component) {
      _.commit('SHOW_STREAMS', true)
      _.commit('SHOW_CONTROLS', true)
      _.commit('REMOVE_ACTION_ENABLED', component)
      _.commit('CLOSE_ACTION')
    }else{
      _.commit('ADD_ACTION_ENABLED', component)
      _.commit('SET_ACTION', component)
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}