const state = () => ({
  mobile: false,
  landscape: false,
  portrait: false,
  isIphone: false,
  showPreloader: false,
})

const getters = {
  isDeviceMobile: state => state.mobile,
  isDeviceLandscape: state => state.landscape,
  isPortrait: state => state.portrait,
  isIphone: state => state.isIphone,
  showPreloader: state => state.showPreloader
}

const mutations = {
  MOBILE_STATE: (state, payload) => {
    state.mobile = payload;
  },
  LANDSCAPE_STATE: (state) => {
    document.body.classList.remove('portrait')
    document.body.classList.add('landscape')
    state.landscape = true
  },
  PORTRAIT_STATE: (state) => {
    document.body.classList.add('portrait')
    document.body.classList.remove('landscape')
    state.portrait = true
  },
  SET_IS_IPHONE: (state, val) => {
    state.isIphone = val
  },
  TOGGLE_PRELOADER: state => state.showPreloader = !state.showPreloader,
  SHOW_PRELOADER: state => state.showPreloader = true,
  HIDE_PRELOADER: state => state.showPreloader = false
}

const actions = {
  WATCH_DEVICE: ({commit}) => {
    window.device.mobile ? commit('MOBILE_STATE', true) : commit('MOBILE_STATE', false) ;
    window.device.landscape ? commit('LANDSCAPE_STATE') :  commit('PORTRAIT_STATE');
    commit('SET_IS_IPHONE', window.device.iphone)
  }
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true
}
