export const features = {
  ru: {
    landing: {
      tabs: {
        features: {
          multicam: {
            title: "многоракурсное видео",
            text: "При многокамерной съёмке добавляйте видеоролики в один плеер или потоки с разных камер в одну трансляцию, чтобы создавать многоракурсное видео."
          },
          scenario: {
            title: "сценарии",
            text: "Создавайте альтернативные сценарии при создании видеоконтента. Позвольте пользователю самому выбрать дальнейший ход событий."
          },
          playlist: {
            title: "плей-листы",
            text: "Создавайте тематические подборки видео или составляйте сетку вещания на каждый день."
          },
          "player-options": {
            title: "подключаемые опции",
            text: "Добавляйте к вашему видео интерактивные опции, чтобы расширить пользовательский опыт"
          },
          "calendar-notify": {
            title: "оповещения",
            text: "Подключайте оповещения чтобы Зрители не пропустили начало трансляции."
          },
          "player-paid": {
            title: "монетизация",
            text: "Ограничивайте доступ к видеоконтенту подключив подписку или создавая закрытые показы."
          },
          statistics: {
            title: "расширенная статистика",
            text: "Статистика и аналитика просмотров."
          },
          integration: {
            title: "интеграция",
            text: "Встраивайте мультипотоковый видеоплеер на свой веб-сайт, чтобы предоставить Вашим Зрителям уникальный опыт при просмотре контента."
          }
        }
      }
    }
  },
  en: {
    landing: {
      tabs: {
        features: {
          multicam: {
            title: "multi-angle video",
            text: "For multi-camera shooting, add videos to one player or streams from different cameras in one broadcast to create multi-angle video."
          },
          scenario: {
            title: "scripts",
            text: "Create alternative scenarios when creating video content. Let the user choose the next course of events."
          },
          "player-options": {
            title: "plug-in options",
            text: "Add interactive options to your video to enhance the user experience"
          },
          playlist: {
            title: "playlists",
            text: "Create thematic video selections or create a broadcast schedule for each day."
          },
          "calendar-notify": {
            title: "notifications",
            text: "Connect alerts so that Viewers do not miss the start of the broadcast."
          },
          "player-paid": {
            title: "monetization",
            text: "Limit access to video content by connecting a subscription or creating private impressions."
          },
          statistics: {
            title: "extended statistics",
            text: "Statistics and analytics of views."
          },
          integration: {
            title: "integration",
            text: "Embed a multi-streaming video player into your website to provide your Viewers with a unique content viewing experience."
          }
        }
      }
    }
  }
}