export default {
  en: {
    "roleType": {
      "all": "All members",
      "user": "User",
      "profile": "Member",
      "person": "Person | Person | Persons | Persons",
      "company": "Company | Company | Company | Companies",
      "community": "Communities | Community | Communities | Communities"
    },
    "banner": {
      "mission" : "Our mission -- remove the barriers to achieve the goal",
      "header": [
        "Online platform",
        "for creative communication"
      ],
      "description": [
        "Here you can declare yourself, assemble a team, organize an event, find clients and performers, optimize costs and save time."
      ]
    },
    "nav-item": {
      main: "Main",
      whoisit: "For whom",
      description: "Description",
      auditory: "Audience",
      members: "Members",
      opportunities: "Opportunities",
      tariffs: "Tariffs",
      roadMap: "Road Map",
      application: "App",
      reviews: "Reviews",
      residents: "Team",
      contacts: "Contacts"
    },
    "policy": "policy",
    "cashback": "cash back",
    "download": "download",
    "choosePlan": "Choose plan",
    "contactManager": "Contact manager",
    "buy": "Buy",
    "renew": "Renew",
    "members": {
      "title": "Members",
      "subTitle": "Creative community"
    }
  }
}
