export const quizFeatures = {
  ru: {
    landing: {
      tabs: {
        quizFeatures: {
          integration: {
            title: "встраивание во внешние веб-сайты",
            text: "Внешнее встраивание квиза в информационную ленту и в тематический раздел веб-сайта, интеграция с другими сервисами проекта."
          },
          statistics: {
            title: "статистика и аналитика",
            text: "Сбор статистики на каждом шаге и аналитика по результатам опроса. "
          },
          'user-statistics': {
            title: "персонализация",
            text: "Формирование индивидуальных опросов и сегментированной выборки целевой аудитории."
          },
          chat: {
            title: "Мессенджеры вместо формы контактов",
            text: "Перенаправление пользователя в чат мессенджера для комфортного продолжения общения."
          }
        }
      }
    }
  },
  en: {
    landing: {
      tabs: {
        quizFeatures: {
          integration: {
            title: "встраивание во внешние веб-сайты",
            text: "Внешнее встраивание квиза в информационную ленту и в тематический раздел веб-сайта, интеграция с другими сервисами проекта."
          },
          statistics: {
            title: "статистика и аналитика",
            text: "Сбор статистики на каждом шаге и аналитика по результатам опроса. "
          },
          'user-statistics': {
            title: "персонализация",
            text: "Формирование индивидуальных опросов и сегментированной выборки целевой аудитории."
          },
          chat: {
            title: "Мессенджеры вместо формы контактов",
            text: "Перенаправление пользователя в чат мессенджера для комфортного продолжения общения."
          }
        }
      }
    }
  }
}