export const playlist = {
  ru: {
    landing: {
      tabs: {
        playlist: {
          calendar: {
            title: "календарь трансляции",
            text: 'Пример опции "оповещение" в сетке вещания программы IT-конференции'
          },
          list: {
            title: "нумерованный список",
            text: "Пример группировки видеофайлов программы онлайн-курса в нумерованный список"
          }
        }
      }
    }
  },
  en: {
    landing: {
      tabs: {
        playlist: {
          calendar: {
            title: "broadcast calendar",
            text: 'An example of the "notification" option in the broadcasting grid of an IT conference program'
          },
          list: {
            title: "numbered list",
            text: "Example of grouping video files of an online course program into a numbered list"
          }
        }
      }
    }
  }
}