export const interactive = {
  ru: {
    landing: {
      tabs: {
        interactive: {
          live: {
            buttonTitle: "Live",
            text: "Ознакомительный фрагмент многоракурсной съёмки концерта, прошедший в Московском международном Доме Музыки."
          },
          vod: {
            buttonTitle: "Vod",
            text: "Фрагмент трансляции. Сычуань, Китай. Маленькие панды из Happiness Village Garden играют, спят, едят и растут в Центре панд Shenshuping Gengda! Гэнда, "
          }
        }
      }
    }
  },
  en: {
    landing: {
      tabs: {
        interactive: {
          live: {
            buttonTitle: "Live",
            text: "Example of grouping video files of an online course program into a numbered list"
          },
          vod: {
            buttonTitle: "Vod",
            text: "Fragment of the broadcast. Sichuan, China. Little pandas from the Happiness Village Garden play, sleep, eat and grow at the Shenshuping Gengda Panda Center! Genda,"
          }
        }
      }
    }
  }
}