<template>
  <div id="app">
    <div class="mvs-body__wrapper">
      <div class="mvs-body__left-side">
        <div class="mvs-header">
          <router-link
            class="mvs-header__logo"
            :to="{name: 'home'}">
            <img src="/images/logo.svg">
          </router-link>
          <div
            class="mvs-header__inner"
            :class="{'--active' : isMobileMenuOpen}">
            <div class="mvs-nav">
              <div
                v-for="(section, index) in menu"
                :key="index"
                class="mvs-nav__item">
                <div
                  v-if="index != 'projects'"
                  class="mvs-nav__title"
                  @click="SET_ACTIVE_MENU(index)">
                  {{ $t(`landing.menu.${index}`) }}
                </div>
                <router-link
                  v-else
                  :to="{name: 'projects'}"
                  exact-active-class="active"
                  class="mvs-nav__title">
                  {{ $t(`landing.menu.${index}`) }}
                </router-link>
                <div
                  v-if="index != 'projects'"
                  v-show="activeMenu === index || isMobileMenuOpen"
                  class="mvs-nav__hidden">
                  <router-link
                    v-for="(item, key) in section"
                    :key="key"
                    :to="{name: key}"
                    exact-active-class="active"
                    class="mvs-nav__link">
                    {{item}}
                  </router-link>
                </div>
              </div>
            </div>
            <div class="mvs-header__social">
              <button
                class="mvs-header__social-link"
                @click="setLanguage">
                <i
                  :name="`mvs-icon-${currentLang === 'ru' ? 'en' : 'ru' }`"
                  class="mvs-icon"
                  size="42"/>
              </button>
              <a href="mailto:main@muvis.media" class="mvs-header__social-link">
                <i name="mvs-icon-email" class="mvs-icon" size="42"/>
              </a>
              <a href="tg://resolve?domain=Runakas" class="mvs-header__social-link">
                <i name="mvs-icon-telegram" class="mvs-icon" size="42"/>
              </a>
              <button
                class="mvs-header__social-link"
                @click="scroollToContacts">
                <i name="mvs-icon-chat" class="mvs-icon" size="42"/>
              </button>
            </div>
          </div>
          <div
            class="mvs-header__hamburger"
            :class="{'--active' : isMobileMenuOpen}"
            @click="isMobileMenuOpen=!isMobileMenuOpen">
            <div class="mvs-header__hamburger-item"></div>
            <div class="mvs-header__hamburger-item"></div>
            <div class="mvs-header__hamburger-item"></div>
          </div>
        </div>
        <div class="mvs-footer__logo-wrapper">
          <a href="/" class="mvs-footer__logo">
            <img src="/images/logo-footer.svg" alt="">
          </a>
        </div>
      </div>
      <div class="mvs-body__right-side">
        <div
          :class="routeClass"
          class="mvs-body">
          <router-view />
        </div>
        <div class="mvs-footer">
          <div class="mvs-wrap">
            <div class="mvs-footer__top">
              <div class="mvs-footer__item --logo">
                <a href="/" class="mvs-footer__logo">
                  <img src="/images/logo-footer.svg" alt="">
                </a>
              </div>
              <div class="mvs-footer__item --links">
                <router-link
                  :to="{name: 'video'}"
                  class="mvs-footer__link">
                  {{ $t('landing.menu.products') }}
                </router-link>
                <router-link
                  :to="{name: 'projects'}"
                  class="mvs-footer__link">
                  {{ $t('landing.menu.projects') }}
                </router-link>
              </div>
              <div class="mvs-footer__item --social">
               <!-- <div class="mvs-footer__social-icons">
                  <a href="https://www.instagram.com/muvis.project/" class="mvs-footer__social-icon">
                    <i name="mvs-icon-instagram" class="mvs-icon"/>
                  </a>
                  <a href="https://www.facebook.com/muvisproject/" class="mvs-footer__social-icon">
                    <i name="mvs-icon-facebook" class="mvs-icon"/>
                  </a>
                </div> -->
              </div>
            </div>
            <div class="mvs-footer__bottom">
              <div class="mvs-footer__personal">
                <router-link
                  :to="{name: 'privacy'}"
                  class="mvs-footer__personal-link">
                  {{ $t('system.policy') }}
                </router-link>
              </div>
              <div class="mvs-footer__copyright">
                Copyright © MUVIS. All Rights reserved. 2015 - 2022.
              </div>
              <div class="mvs-footer__requisites">
                {{ $t('system.requisites') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <cookie-banner
      v-if="!cookies"
      class="cookie-banner"
      @cookie-accept="COOKIES_ACCEPTED" />
  </div>
</template>
<script>
import { i18n } from './main.js'
import { mapGetters, mapMutations, mapActions } from 'vuex'

import CookieBanner from '@/components/CookieBanner.vue'
export default {
  name: 'App',
  components: {
    CookieBanner
  },
  data: () => ({

  }),
  computed: {
    isMobileMenuOpen: {
      get(){
        return this.$store.getters[`landing/isMobileMenuOpen`]
      },
      set(isOpen){
        this.SET_MOBILE_MENU_OPEN(isOpen)
      }
    },
    ...mapGetters({
      cookies : 'landing/cookies',
      activeMenu : 'landing/activeMenu',
      currentLang : 'landing/currentLang',
      menu : 'landing/menu',
      slides : 'landing/slides',
    }),
    routeClass(){
      if (this.$route.name) {
        return this.$route.name.toLowerCase()
      }
      return ''
    }
  },
  updated () {
    this.WATCH_DEVICE();
    this.$scrollTo('body', 200)
  },
  // beforeRouteUpdate (to, from, next) {
  //   console.log('beforeRouteUpdate');
  //   this.WATCH_DEVICE();
  //   this.$scrollTo('body', 200)
  //   next()
  // },
  methods: {
    ...mapActions({
      WATCH_DEVICE: 'device/WATCH_DEVICE',
      COOKIES_ACCEPTED: 'landing/COOKIES_ACCEPTED',
    }),
    ...mapMutations({
      SET_MOBILE_MENU_OPEN: 'landing/SET_MOBILE_MENU_OPEN',
      SET_ACTIVE_MENU: 'landing/SET_ACTIVE_MENU',
      SET_CURRENT_LANG: 'landing/SET_CURRENT_LANG',
    }),
    scroollToContacts(){
      if (this.$route.name === 'home') {
        this.$scrollTo('#contact', 200)
      }else{
        this.$router.push({name: 'home'})
        setTimeout(() => this.$scrollTo('#contact', 200), 100)
      }

    },
    setLanguage(){
      const selectLang = this.currentLang === 'ru' ? 'en' : 'ru'
      i18n.locale = selectLang;
      this.SET_CURRENT_LANG(selectLang);
    },
  },
};
</script>
<style lang="sass">
  .mvs-header__social
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    &-link
      width: 42px
      height: 42px
      font-size: 42px
      color: $light
      opacity: .8
      transition: .2s
      cursor: pointer
      &:hover
        color: #fff
    &-icons
      display: flex
      align-items: center
      @media only screen and (max-width: 767px)
        justify-content: center
        margin-bottom: 10px
    &-icon
      font-size: 70px
      transition: .2s
      margin-right: 50px
      &:last-child
        margin-right: 0
      @media only screen and (max-width: 1279px)
        font-size: 70px
      @media only screen and (max-width: 767px)
        font-size: 24px
        margin-right: 20px
        &:last-child
          margin-right: 0
      &:hover
        color: #fff


  .mvs-footer__social
    &-icons
      display: flex
      flex-direction: row
      justify-content: flex-end
      align-items: center
      @media only screen and (max-width: 767px)
        justify-content: center
        margin-bottom: 10px
    &-icon
      font-size: 65px
      transition: .2s
      margin-right: 45px
      &:last-child
        margin-right: 0
      @media only screen and (max-width: 767px)
        font-size: 24px
        margin-right: 20px
      &:hover
        color: #fff

  .mvs-footer
    height: 430px
    color: $light
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    width: 100%
    max-width: 880px
    padding: 50px 0

    &:before
      content: ""
      position: absolute
      left: 0
      height: 4px
      background: #fff
      width: 100%
    @media only screen and (max-width: 767px)
      margin-top: 10px
      padding: 20px 0
    .mvs-wrap
      width: 100%
      @media only screen and (max-width: 767px)
        max-width: 100%
    &__top
      margin-top: 60px
      display: flex
      flex-direction: row
      flex-wrap: wrap
      align-items: center
      justify-content: flex-end
      margin-bottom: 60px
      @media only screen and (max-width: 767px)
        flex-direction: row
        justify-content: flex-start
        padding: 0 22px
        margin-bottom: 10px
    &__bottom
      text-align: center
      font-size: 20px
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      width: 70%
      @media only screen and (max-width: 767px)
        font-size: 12px
        padding: 0 22px
    &__item
      &.--logo
        display: none
      &.--social
        max-width: 40%
        flex-grow: 1
      &.--links
        flex-grow: 1
        width: 65%
        display: flex
        flex-direction: row
        flex-wrap: wrap
        align-items: center
        justify-content: flex-start
        .mvs-footer__link
          display: flex
          flex-grow: 1
          flex-direction: row
          flex-wrap: wrap
          align-items: center
          justify-content: flex-end
          margin-right: 70px
          &:last-child
            justify-content: flex-start
            margin-right: unset
            margin-left: 50px
    &__logo
      display: block
      width: 230px
      &-wrapper
        position: absolute
        bottom: 245px
        margin-right: 15px
      @media only screen and (max-width: 1279px)
        width: 250px
      @media only screen and (max-width: 767px)
        flex-grow: 1
        width: 100%
        img
          max-width: 50%
      img
        width: 100%
    &__links
      display: flex
      align-items: center
    &__link
      text-transform: uppercase
      font-size: 25px
      transition: .2s
      @media only screen and (max-width: 1279px)
        margin-right: 50px
        font-size: 28px
      @media only screen and (max-width: 767px)
        font-size: 14px
        font-weight: 700
        margin-right: 35px
      &:hover
        color: #fff
      &:last-child
        margin-right: 0
    &__personal
      margin-bottom: 40px
      @media only screen and (max-width: 767px)
        margin-bottom: 20px
      &-link
        color: #979797
        line-height: 1.2
        border-bottom: 1px solid #979797
        font-size: 18px
        font-weight: 300
        &:hover
          border: none
    &__copyright
      margin-bottom: 15px
      font-size: 18px
      font-weight: 300
      color: #5c5c5c
      @media only screen and (max-width: 767px)
        font-size: 14px
    &__requisites
      font-size: 14px
      line-height: 1.2
      font-weight: 300
      color: #5c5c5c
      @media only screen and (max-width: 767px)
        font-size: 12px
</style>
