<template>
  <div
    :id="slideId"
    :class="`--${sliderType}`"
    class="mvs-tab">
    <div class="mvs-tab__header">
      <button
        v-for="(tab, index) in options"
        :key="tab"
        :name="`mvs-icon-${tab}`"
        :size="iconSize ? iconSize : null"
        :class="[{'--active': activeTab === tab}, {icon: sliderType === 'icons'}]"
        class="mvs-tab__link"
        @click="clickTab(index)">
        <template v-if="sliderType === 'text'">
          {{ $t(`landing.tabs.${slideId}.${tab}.buttonTitle`) }}
        </template>
      </button>
    </div>
    <slot name="middle" />
    <div class="mvs-tab__body">
      <swiper
        :ref="`${slideId}Swiper`"
        :id="`${slideId}__swiper`"
        :options="slideOptions"
        :class="`mvs-${slideId}__slider`"
        @slideChange="changeSwiperIndex"
        @slideChangeTransitionStart="$emit('changeStart', true)">
        <swiper-slide
          v-for="tab in options"
          :key="`${slideId}-slide-${tab}`"
          class="mvs-tab__block">
          <div
            v-if="titles"
            class="mvs-tab__title">
            {{ $t(`landing.tabs.${slideId}.${tab}.title`) }}
          </div>
          <div
            v-if="description"
            class="mvs-tab__description">
            {{ $t(`landing.tabs.${slideId}.${tab}.text`) }}
            <slot name="description" />
          </div>
          <slot name="content" />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TabsSlider',
  components: {

  },
  props: {
    sliderType: {
      type: String,
      default: 'icons',
      validator: (value) => ['icons', 'text', 'simple'].indexOf(value) !== -1
    },
    titles: Boolean,
    description: Boolean,
    iconSize: {
      type: Number,
      default: 0
    },
    slideId: {
      type: String,
      default: 'sliderId'
    },
    options: {
      type: Array,
      default: () =>([])
    },
    slideOptions: {
      type: Object,
      default: () =>({})
    },
  },
  data: () => ({
    activeTab: ''
  }),
  computed: {

  },
  watch: {
    activeTab(tab){
      this.$emit('changeSlide', tab)
    }
  },
  created() {

  },
  mounted() {
    this.activeTab = this.options[0]
  },
  methods: {
    clickTab(index){
      this.activeTab = this.options[index]
      this.$refs[`${this.slideId}Swiper`].$swiper.slideTo(index)
      // console.log('clickTab');
    },
    changeSwiperIndex(){
      const activeIndex = this.$refs[`${this.slideId}Swiper`].$swiper.activeIndex
      this.activeTab = this.options[activeIndex]
      // console.log('changeSwiperIndex');
    }
  },
};
</script>
<style lang="sass">
  .mvs-player__wrap
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
  .mvs-player__wrap_swiper-controls
    position: absolute
    top: -60px
    z-index: 2
    width: 85px
    align-self: flex-end
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    margin-bottom: 14px
    button
      cursor: pointer
      &.--disabled
        color: #979797
        cursor: disabled
      &:hover
        color: #979797
</style>