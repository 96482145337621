export default {
  ru: {
    "roleType": {
      "all": "Все участники",
      "user": "Пользователь",
      "profile": "участник",
      // 'Подписчиков | Подписчик | Подписчика | Подписчиков | Подписчика'
      "person": "Персон | Персона | Персоны | Персон | Персоны",
      "company": "Компаний | Компания | Компании | Компаний | Компании",
      "community": "Сообществ |Сообщество | Сообщества | Сообществ | Сообщества"
    },
    "banner": {
      "mission": "НАША МИССИЯ - УБРАТЬ БАРЬЕРЫ НА ПУТИ К ЦЕЛИ",
      "header": [
        "Онлайн-платформа",
        "для творческих коммуникаций"
      ],
      "description": [
        "Здесь Вы можете заявить о себе, собрать команду, организовать мероприятие, найти клиентов и исполнителей, оптимизировать расходы и сэкономить время."
      ]
    },
    "nav-item": {
      "main": "Главная",
      "whoisit": "Для кого",
      "description": "Описание",
      "opportunities": "Возможности",
      "members": "Участники",
      "auditory": "Аудитория",
      "tariffs": "Тарифы",
      "roadMap": "Дорожная карта",
      "application": "Приложение",
      "reviews": "Отзывы",
      "residents": "Команда",
      "contacts": "Контакты"
    },
    "policy": "Политика конфидициальности",
    "cashback": "Кешбэк",
    "download": "Скачай",
    "choosePlan": "Выберите тарифный план",
    "contactManager": "Связаться с менеджером",
    "buy": "Купить",
    "renew": "Продлить",
    "members": {
      "title": "Участники",
      "subTitle": "Творческое сообщество"
    },
  }
}
