export const quiz = {
  ru: {
    landing: {
      quiz: {
        title: "ИНТЕРАКТИВНЫЕ ОПРОСЫ И ТЕСТЫ",
      }
    }
  },
  en: {
    landing: {
      quiz: {
        title: "INTERACTIVE SURVEYS AND TESTS",
      }
    }
  }
}