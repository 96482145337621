import Vue from 'vue'
import App from './App.vue'

import '@/sass/style.sass'
import Vuex from 'vuex';
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'

import store from './store';
import TabsSlider from '@/components/TabsSlider.vue'
import { device } from 'device.js';
window.device = device
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.$cookies.config('7d')
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import {Swiper as SwiperClass, Pagination, Navigation, Mousewheel, Autoplay} from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
SwiperClass.use([Pagination, Mousewheel, Navigation, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
import 'swiper/swiper-bundle.min.css'
import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import 'moment/locale/ru'
Vue.use(VueMoment, {
  moment
})
import globalMixin from './globalMixin'
Vue.mixin(
  globalMixin
)
window.moment = moment
Vue.use(Tooltip);
Vue.component('tabs-slider', TabsSlider);
Vue.use(VueRouter)
Vue.use(VueI18n)
import router from './router'
import { languages } from './i18n/index.js'
import { defaultLocale } from './i18n/index.js'
const messages = Object.assign(languages)
export var i18n = new VueI18n({
  locale: defaultLocale,
  silentFallbackWarn: true,
  pluralizationRules: {
    /**
     * @param choice {number} индекс выбора, переданный в $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} общее количество доступных вариантов
     * @returns финальный индекс для выбора соответственного варианта слова
     */
    'ru': function(choice, choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }
      return (choicesLength < 4) ? 2 : 3;
    }
  },
  messages
})
var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo)

// You can also pass in the default options
Vue.use(VueScrollTo, {
     container: "body",
     duration: 500,
     easing: "ease",
     offset: 0,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
 })


// set BrowserSupport classes
device.addClasses(document.documentElement);
let isSafari

let ua = navigator.userAgent.toLowerCase();
if (ua.indexOf('safari') != -1) {
  if (ua.indexOf('chrome') > -1) {
    isSafari = false
  } else {
    isSafari = true
  }
}

let isHuaweiBrowser
if (ua.includes('huaweibrowser')) {
  isHuaweiBrowser = true
} else {
  isHuaweiBrowser = false
}

let isIE11 = !!window.MSInputMethodContext && !!document.documentMode
if (isSafari){
  document.documentElement.classList.add("safari")
}
if (isIE11){
  document.documentElement.classList.add("ie11")
}
if (isHuaweiBrowser){
  document.documentElement.classList.add("huaweibrowser")
}
if (window.device.iphone){
  document.documentElement.classList.add("iphone")
}
if (window.navigator.userAgent.indexOf("Edge") > -1) {
  document.documentElement.classList.add("edge")
}



Vue.config.productionTip = false
Vue.use(Vuex);
new Vue({
  router,
  store,
  i18n,
  created() {
    this.$moment.locale('ru')
  },
  render: h => h(App),
}).$mount('#app')
