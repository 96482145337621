export const contact = {
  ru: {
    landing: {
      contact: {
        title: "СООБЩЕСТВО",
        description: [
          'Целью платформы является создание коммуникационной среды и расширение возможностей для развития бизнеса профессиональных сообществ за счёт предложенных платформой готовых технологических решений.',
          'Платформа предусматривает возможность встраивания ее компонентов на внешние клиентские веб-сайты, фактически расширяя их функциональные и технические возможности.',
          'Каждый компонент платформы – это отдельный полноценный сервис, как часть общего технического решения дополняющий друг друга определенными функциональными возможностями в составе замкнутой многофункциональной экосистемы.'
        ]
      }
    }
  },
  en: {
    landing: {
      contact: {
        title: "COMMUNITY",
        description: [
          'Целью платформы является создание коммуникационной среды и расширение возможностей для развития бизнеса профессиональных сообществ за счёт предложенных платформой готовых технологических решений.',
          'Платформа предусматривает возможность встраивания ее компонентов на внешние клиентские веб-сайты, фактически расширяя их функциональные и технические возможности.',
          'Каждый компонент платформы – это отдельный полноценный сервис, как часть общего технического решения дополняющий друг друга определенными функциональными возможностями в составе замкнутой многофункциональной экосистемы.'
        ]
      }
    }
  }
}