const merge = require('deepmerge')
import { video } from './video'
import { features } from './tabs/features'
import { extended } from './tabs/extended'
import { howItWorks } from './tabs/howItWorks'
import { interactive } from './tabs/interactive'
import { playlist } from './tabs/playlist'

export const videoPage = merge.all([
  video,
  features,
  extended,
  howItWorks,
  interactive,
  playlist,
])